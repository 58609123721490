import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { MDXRenderer } from "gatsby-plugin-mdx"
import MdxLink from '../components/MdxLink';

const Service = ({ data }) => {
  const { title } = data.mdx.frontmatter;
  const { body } = data.mdx;
  return (
    <>
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <div className="service service-single">
              <h1 className="title">{title}</h1>
              <div className="content"><MDXRenderer components={{ a: MdxLink }}>{ body }</MDXRenderer></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
      body
    }
  }
`;

export default Service;
